import { ReactNode } from 'react';
import classNames from 'classnames';
import {
    camelCase,
    chain,
    cloneDeep,
    sortBy,
    uniqBy as uniqueBy,
    orderBy,
} from 'lodash';
import { transformers } from '@flo-concepts/data-parser';
import { FloTypes, Session } from '@flo-concepts/flo-types';
import { Place } from '@models';
import {
    SourceObjectType,
    ZoneObjectType,
} from '@components/SolverSessions/SessionCreator/SessionCreator.model';
import {
    CompoundWithZoneOrders,
    State,
    ZoneWithOrders,
} from './MainReducer.model';

export const NO_ZONE_LABEL = 'No Zone';

export const convertSnakeCaseKeys = <F, T>(lastSyncData: F): T => {
    return Object.keys(lastSyncData).reduce((acc, key) => {
        const camelCasedKey = camelCase(key) as keyof T & string;
        (acc as Record<string, any>)[camelCasedKey] =
            lastSyncData[key as keyof F];
        return acc;
    }, {} as T);
};

export const createZonesFromOrders = (
    orders: State['orders']
): Array<CompoundWithZoneOrders> => {
    const groupedByCompounds = chain(orders || [])
        .map((o) => ({
            ...cloneDeep(o),
            fromName: o.from.name,
            zone: o.to.group,
        }))
        .groupBy('fromName')
        .map((value, key) => ({ compound: key, orders: value }))
        .value();

    const groupedOrders = groupedByCompounds.map((c) => {
        const groupedByZones = chain(c.orders)
            .groupBy('zone')
            .map((value, key) => ({ zone: key, orders: value }))
            .value();

        const zones = groupedByZones.map((grouped) => {
            const zoneOrders = grouped.orders.map((o) => {
                return {
                    id: o.orderID,
                    from: o.from.name,
                    to: o.to.name,
                    vin: o.vin,
                    city: o.to.location.city,
                    name: o.vehicle.name,
                    priority: o.priority,
                    postCode: o.to.location.postCode,
                };
            });
            return {
                zone: grouped.zone,
                orders: zoneOrders,
                orderNumber: zoneOrders.length,
            };
        });

        return {
            compound: c.compound,
            zones: orderBy(zones, ['zone'], ['asc']),
            orderNumber: c.orders.length,
        };
    });

    return orderBy(groupedOrders, ['orderNumber'], ['desc']);
};

export const generateScopedColumn = (
    value: string | ReactNode,
    className?: string,
    testId?: string
) => {
    return (
        <td data-testid={testId} className={classNames(className)}>
            {value}
        </td>
    );
};
export const cleanPlaces = (places: Array<Place>) =>
    transformers.cleanPlaces(
        (places || []).map((p) => ({
            ...p,
            zoneGrouping: p.zone || '',
            postcode: p.postCode || '',
            type: p.placeType as FloTypes.PlaceType,
        }))
    );

export const createListOfCompounds = (
    orders: Array<FloTypes.Hydrated.Order>
) => {
    if (!orders?.length) return [];
    return sortBy(
        uniqueBy(
            [
                ...orders.map((order) => {
                    return {
                        value: order.fromPlaceID,
                        name: order.from.name,
                        type: 'from',
                    };
                }),
                ...orders.map((order) => {
                    return {
                        value: order.toPlaceID,
                        name: order.to.name,
                        type: 'to',
                    };
                }),
            ],
            'value'
        ),
        'name'
    );
};

export const mapOrderPlaceToCompound = (
    name: string,
    placeID: string,
    placeType: string,
    type: 'from' | 'to',
    orders: number,
    zones: Array<ZoneWithOrders>
) => ({
    name,
    placeID,
    placeType,
    type,
    orders,
    zones: zones
        ?.map(
            ({ zone, orderNumber }: { zone: string; orderNumber: number }) => ({
                name: zone,
                orders: orderNumber,
            })
        )
        .sort(({ name: za }: ZoneObjectType, { name: zb }: ZoneObjectType) => {
            if (za < zb) return -1;
            if (za > zb) return 1;
            return 0;
        }),
});

export const createCompoundsOfOrders = (
    orders: Array<FloTypes.Hydrated.Order>,
    zones: Array<CompoundWithZoneOrders>
) => {
    if (!orders?.length) return [];

    const noZonesOrdersCount: Record<string, number> = {};

    orders.forEach(({ to: { name, placeID } }) => {
        const isZone = zones.find((z) => z.compound === name);
        if (!isZone)
            noZonesOrdersCount[placeID] =
                (noZonesOrdersCount[placeID] || 0) + 1;
    });

    const noZonesMap = Object.keys(noZonesOrdersCount).map((placeID) => ({
        placeID,
        orderNumber: noZonesOrdersCount[placeID],
    }));

    return sortBy(
        uniqueBy(
            [
                ...orders.map((order) => {
                    const compound = zones.find(
                        (z: { compound: string }) =>
                            z.compound === order.from?.name
                    );

                    if (compound)
                        return mapOrderPlaceToCompound(
                            order.from.name,
                            order.fromPlaceID,
                            order.from.type,
                            'from',
                            compound.orderNumber,
                            compound.zones
                        );

                    return null;
                }),
                ...orders.map((order) => {
                    let compound;

                    const noZoneCompound = noZonesMap.find(
                        ({ placeID }) => placeID === order.toPlaceID
                    );

                    if (noZoneCompound) {
                        compound = {
                            ...noZoneCompound,
                            zones: [
                                {
                                    zone: NO_ZONE_LABEL,
                                    orderNumber: noZoneCompound.orderNumber,
                                    orders: [],
                                },
                            ],
                        };
                    } else {
                        compound = zones.find(
                            (z: { compound: string }) =>
                                z.compound === order.to?.name
                        );
                    }

                    if (compound)
                        return mapOrderPlaceToCompound(
                            order.to.name,
                            order.toPlaceID,
                            order.to.type,
                            'to',
                            compound.orderNumber,
                            compound.zones
                        );

                    return null;
                }),
            ].filter((c) => c),
            'placeID'
        ),
        'name'
    );
};

export const createCompoundsFromPlaces = (
    places: Array<FloTypes.Place>,
    zones: Array<CompoundWithZoneOrders>,
    compoundsOnly = false
) => {
    return places
        .filter((place) => {
            return compoundsOnly ? place.type === 'compound' : true;
        })
        .map(({ name, placeID }: { name: string; placeID: string }) => {
            const compound = zones.find(
                (z: { compound: string }) => z.compound === name
            );
            if (compound) {
                return {
                    name,
                    placeID,
                    orders: compound.orderNumber,
                    zones: compound.zones
                        ?.map(
                            ({
                                zone,
                                orderNumber,
                            }: {
                                zone: string;
                                orderNumber: number;
                            }) => ({
                                name: zone,
                                orders: orderNumber,
                            })
                        )
                        .sort(
                            (
                                { name: za }: ZoneObjectType,
                                { name: zb }: ZoneObjectType
                            ) => {
                                if (za < zb) return -1;
                                if (za > zb) return 1;
                                return 0;
                            }
                        ),
                };
            }
            return null;
        })
        .filter((cmp: SourceObjectType | null) => cmp)
        .sort(
            (
                { orders: ca }: SourceObjectType,
                { orders: cb }: SourceObjectType
            ) => {
                if (ca < cb) return 1;
                if (ca > cb) return -1;
                return 0;
            }
        );
};

export const createResourcesFromTransporters = (
    transporters: Array<Session.Transporter>
) =>
    transporters.map((t: { name: string }) => ({
        id: t.name.replace(/[^a-zA-Z0-9]+/g, ''),
        name: t.name,
    }));
